import * as React from 'react';

const Checkmark = ({ size }) => (
  <div
    style={{
      height: size,
      width: size,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      viewBox="0 0 16 12"
    >
      <path
        fill="#00FFB3"
        fillRule="evenodd"
        d="M.5 5.47L2 3.97l3.5 2.5L14-.03l1.5 1.5-10 10z"
      />
    </svg>
  </div>
);

export default Checkmark;
