import * as React from "react";
import styled from "@emotion/styled";

import { Button } from "./Button";
import Checkmark from "../icons/Checkmark";
import IconMealLogo from "./IconMealLogo";

const MikesPurchaseComplete: React.FunctionComponent<{}> = () => {
    return (
        <PurchaseComplete>
            <PurchaseSuccessBox>
                <IconImage />
                <PurchaseTextArea>
                    <Checkmark size="10px" />
                    <PurchaseText>Purchase Successful!</PurchaseText>
                </PurchaseTextArea>
            </PurchaseSuccessBox>
            <PurchaseInfoBox>
                <OpenButton to="https://fitplan.app.link/downloadapp">Open App</OpenButton>
                <TextBox>
                    A confirmation email has been sent to your inbox. Check it
                    out for all the info you need to <b>get started</b>, plus
                    how to get meals designed for this challenge delivered to
                    your door!
                </TextBox>
                <IconMealLogo />
            </PurchaseInfoBox>
        </PurchaseComplete>
    );
};

const BarlowStyle = styled.div`
    font-family: "Barlow";
`;

const PurchaseComplete = styled.div`
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.1);

    width: 82vw;
    max-width: 402px;
    padding: 16px;
    margin: 10px auto;
`;

const PurchaseSuccessBox = styled.div`
    border-radius: 5px;
    box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
    background-color: #1d222b;

    padding: 16px;
    display: flex;
    position: relative;
`;

const IconImage = styled.div`
    background-image: url(https://downloads.intercomcdn.com/i/o/168522641/d8dd099aeecb233c82a49ae8/mike+circle-1.png);
    background-size: cover;
    border-radius: 100%;

    width: 80px;
    height: 80px;
    position: absolute;
    top: -12px;
    left: -12px;
    overflow: hidden;
`;

const PurchaseTextArea = styled(BarlowStyle)`
    color: #fff;
    font-size: 17px;
    line-height: 1.41;

    margin: 0 auto;
    padding-left: 50px;
    display: flex;
`;

const PurchaseText = styled.div`
    padding: 0 24px 0 8px;
`;

const PurchaseInfoBox = styled(BarlowStyle)`
    font-size: 17px;
    text-align: center;
    line-height: 1.41;

    padding: 48px 0 0;
`;

const OpenButton = styled(Button)`
    max-width: 300px;
    margin: 0 auto;

    :hover,
    :focus {
        background-image: linear-gradient(257deg, #00bd98, #28d95a);
    }
`;

const TextBox = styled.p`
    color: #000;
    font-size: 17px;
    line-height: 1.41;
    text-align: center;

    margin: 20px 0 0;
    padding: 20px 30px;
`;

export default MikesPurchaseComplete;
