import * as React from "react";
import { StripeProvider, Elements } from "react-stripe-elements";
import { gtagPageView } from "../../analytics";
import useMount from "react-use/lib/useMount";
import { useConfig } from "@fitplan/context/lib-es/config";
import { IOneTimeProduct } from "../../types";

import { InjectedForm } from "./InjectedForm";
import { Text, Header } from "../NewSubscriptionModal/elements";
import { useTatari } from "../../analytics/useTatari";

export interface Props {
    clientSecret: string;
    product: IOneTimeProduct;
    disabled: boolean;
    onComplete: () => void;
    language: "en" | "es";
}

export const PaymentForm: React.FunctionComponent<Props> = ({
    disabled,
    onComplete,
    language,
    clientSecret,
    product,
    ...props
}) => {
    const [stripe, setStripe] = React.useState<any>(null);
    const { isProduction, stripePublicKey } = useConfig();
    const { tatariTrack } = useTatari();
    useMount(() => {
        setStripe((window as any).Stripe(stripePublicKey));
        gtagPageView(`/product/${product.code}`, undefined, isProduction);
        tatariTrack("pageview", { path: `/product/${product.code}`, product });
    });
    const paymentRequest = React.useMemo(() => {
        if (!stripe) {
            return null;
        }
        if (product) {
            return stripe.paymentRequest({
                country: "US",
                currency: "usd",
                total: {
                    amount: product.amount,
                    label: product.name,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });
        }
        return null;
    }, [stripe, product]);

    return (
        <>
            <Header disabled={disabled}>2. Enter Payment Info</Header>
            <Text disabled={disabled}>Choose payment type:</Text>
            {paymentRequest && (
                <StripeProvider stripe={stripe}>
                    <Elements>
                        <InjectedForm
                            disabled={disabled}
                            paymentRequest={paymentRequest}
                            product={product}
                            clientSecret={clientSecret}
                            onComplete={onComplete}
                            language={language}
                        />
                    </Elements>
                </StripeProvider>
            )}
        </>
    );
};
