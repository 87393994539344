import * as React from "react";
import styled from "@emotion/styled";
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe,
    ReactStripeElements
} from "react-stripe-elements";
import useMount from "react-use/lib/useMount";

import { InfoIcon } from "../NewSubscriptionModal/InfoIcon";
import { PayNowButton } from "../NewSubscriptionModal/PaymentButton";
import { CVVinfoIcon } from "../NewSubscriptionModal/CVVinfoIcon";
import { Separator } from "../NewSubscriptionModal/Separator";
import { IOneTimeProduct } from "../../types";
import { BoldText, Error, disabledColor } from "../NewSubscriptionModal/elements";
import { BrandButton } from "../Button/BrandButton";
import { sentryReportException } from "../../utils/sentry";
import { useAuthContext } from "@fitplan/context/lib-es/auth";

export interface Props {
    stripe?: any;
    paymentRequest: any;
    clientSecret: string;
    product: IOneTimeProduct;
    disabled: boolean;
    language: "en" | "es";
    onComplete: () => void;
}

export interface InjectFormStrings {
    payment: {
        title: string;
        change: string;
        error: string;
        userError: string;
        dueAfterTrial: string;
        totalDue: string;
        terms: {
            quarterly: string;
            monthly: string;
            annual: string;
            invoice: string;
        };
        promoExceptionError: string;
    };
    subscription: {
        coupon: {
            enter: string;
            apply: string;
            error: string;
        };
    };
}

const InnerInjectedForm: React.FunctionComponent<Props> = ({
    disabled,
    stripe,
    product,
    clientSecret,
    paymentRequest,
    onComplete,
    language,
    ...props
}) => {
    const [isInfoVisible, setIsInfoVisible] = React.useState(false);
    const [cardError, setCardError] = React.useState("");
    const [dateError, setDateError] = React.useState("");
    const [cvvError, setCvvError] = React.useState("");
    const [globalError, setGlobalError] = React.useState("");
    const [pendingRequest, setPendingRequest] = React.useState(false);
    const [isGooglePayEnabled, setIsGooglePayEnabled] = React.useState(false);
    const [isApplePayEnabled, setIsApplePayEnabled] = React.useState(false);
    const [cardNumberComplete, setCardNumberComplete] = React.useState(false);
    const [expDateComplete, setExpDateComplete] = React.useState(false);
    const [cvvComplete, setCvvComplete] = React.useState(false);
    const [cardElement, setCardElement] = React.useState(null);
    const { user } = useAuthContext();
    const buttonRef = React.useRef<HTMLButtonElement>();
    useMount(() => {
        paymentRequest.on(
            "paymentmethod",
            ({
                paymentMethod,
                error,
                complete
            }: {
                paymentMethod: { id: string };
                error: any;
                complete: (result: "success" | "fail") => void;
            }) => {
                if (paymentMethod) {
                    onSubmit(paymentMethod, complete);
                } else if (error) {
                    setGlobalError(error);
                }
            }
        );

        paymentRequest.canMakePayment().then((result: { applePay: boolean }) => {
            if (result) {
                if (result.applePay) {
                    setIsApplePayEnabled(true);
                } else {
                    setIsGooglePayEnabled(true);
                }
            }
        });
    });
    const onChange = (setComplete: (val: boolean) => void, setError: (err: string) => void) => (
        event: ReactStripeElements.ElementChangeResponse
    ) => {
        setError(event?.error?.message ?? "");
        setComplete(!!event.complete);
    };
    const onShowRequest = () => {
        paymentRequest.show();
    };

    const onSubmit = async (
        source?: { id: string },
        complete?: (val: "success" | "fail") => void,
    ) => {
        if (pendingRequest) {
            return;
        }
        setPendingRequest(true);
        if (buttonRef?.current) {
            buttonRef.current.disabled = true;
        }
        try {
            const { paymentIntent, error } = source
                ? await stripe.confirmCardPayment(clientSecret, {
                    payment_method: source.id,
                }, {handleActions: false}) : await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            email: user.username
                        }
                    },
                    use_stripe_sdk: true
                });

            if (error) {
                console.error(error);
                sentryReportException(error);
                setGlobalError(error?.message);
                complete?.("fail");
            } else if (paymentIntent?.status === "succeeded") {
                // Success with no 3DS
                onComplete();
                complete?.("success");
            } else {
                const { error: handleError } = await stripe.handleCardPayment(clientSecret);
                console.error(handleError);
                if (handleError) {
                    // 3DS failed
                    sentryReportException(handleError);
                    setGlobalError(handleError?.message);
                } else {
                    // 3DS Succeeded
                    onComplete();
                }
            }
            setPendingRequest(false);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error) {
                setGlobalError(err.response.data.error);
            }
            setPendingRequest(false);
            sentryReportException(err);
            complete?.("fail");
        }
    };

    return (
        <form
            onSubmit={async event => {
                event.preventDefault();
                setPendingRequest(true);
                if (buttonRef && buttonRef.current) {
                    buttonRef.current.disabled = true;
                }
                await onSubmit();
            }}
        >
            <Margin>
                <PayNowButton
                    type="button"
                    isApple
                    disabled={disabled || !isApplePayEnabled}
                    onClick={onShowRequest}
                />
                <PayNowButton
                    type="button"
                    disabled={disabled || !isGooglePayEnabled}
                    onClick={onShowRequest}
                />
            </Margin>
            <Margin>
                <Separator disabled={disabled} text="Or Pay using Credit Card" />
            </Margin>
            <Container>
                <CardNumber>
                    <Border>
                        <CardNumberElement
                            {...options}
                            style={{ ...(disabled ? disabledStyle : stripeStyle) }}
                            onChange={onChange(setCardNumberComplete, setCardError)}
                            disabled={disabled}
                            onReady={el => setCardElement(el)}
                        />
                    </Border>
                    {!!cardError && !disabled && <Error>{cardError}</Error>}
                </CardNumber>
                <ExpirationDate>
                    <Border>
                        <CardExpiryElement
                            {...options}
                            style={{ ...(disabled ? disabledStyle : stripeStyle) }}
                            placeholder="MM/YY"
                            onChange={onChange(setExpDateComplete, setDateError)}
                            disabled={disabled}
                        />
                    </Border>
                    {!!dateError && !disabled && <Error>{dateError}</Error>}
                </ExpirationDate>
                <CVVNumber>
                    <Border>
                        <CardCVCElement
                            {...options}
                            style={{ ...(disabled ? disabledStyle : stripeStyle) }}
                            placeholder="CVV"
                            onChange={onChange(setCvvComplete, setCvvError)}
                            disabled={disabled}
                        />
                    </Border>
                    <InfoButton
                        disabled={disabled}
                        onMouseEnter={() => !disabled && setIsInfoVisible(true)}
                        onMouseLeave={() => setIsInfoVisible(false)}
                    >
                        <InfoIcon />
                    </InfoButton>
                    {isInfoVisible && (
                        <InfoPopup>
                            <CVVinfoIcon />
                        </InfoPopup>
                    )}
                    {!!cvvError && !disabled && <Error>{cvvError}</Error>}
                </CVVNumber>
            </Container>
            {!!globalError && !disabled && <Error>{globalError}</Error>}
            <BoldText disabled={disabled}>Total due today: ${product.amount / 100}</BoldText>
            <Button
                ref={buttonRef}
                type="submit"
                disabled={
                    !(cardNumberComplete && cvvComplete && expDateComplete) ||
                    disabled ||
                    pendingRequest
                }
            >
                Buy Now
            </Button>
        </form>
    );
};

export const InjectedForm = injectStripe(InnerInjectedForm);

const Container = styled.div`
    display: grid;
    width: 100%;
    box-sizing: border-box;

    grid-template-areas:
        "number number"
        "date cvv";
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    margin: 16px 0;
`;

const CardNumber = styled.div`
    grid-area: number;
`;

const Border = styled.div<{ disabled?: boolean }>`
    padding-bottom: 2px;
    border-bottom: solid 1px ${props => (props.disabled ? disabledColor : `#ccc`)};
`;

const ExpirationDate = styled(CardNumber)`
    grid-area: date;
`;

const CVVNumber = styled(CardNumber)`
    grid-area: cvv;
    position: relative;
`;

const InfoButton = styled.button`
    position: absolute;
    top: 6px;
    right: 4px;
    padding: 0;
    margin: 0;
    background: none;
    border: none;

    :focus,
    :active,
    :hover {
        outline: none;
    }
`;

const InfoPopup = styled.div`
    z-index: 10;
    background-color: #ffffff;
    position: absolute;
    right: calc(-50%);
    height: 122px;
    border-radius: 5px;
    box-shadow: 0px 8px 14px 0px rgba(0, 0, 0, 0.1);
`;

const Margin = styled.div`
    margin: 16px 0;
`;

const stripeStyle = {
    base: {
        color: "#444",
        lineHeight: "24px",
        fontWeight: 400,
        fontFamily: "Barlow Regular, sans-serif",
        fontSize: "17px",
        "::placeholder": {
            color: "#757575"
        }
    },
    invalid: {
        color: "#f00"
    }
};

const options: ReactStripeElements.ElementsOptions = {
    style: stripeStyle
};

const disabledStyle = {
    base: {
        color: "#757575",
        lineHeight: "24px",
        fontWeight: 400,
        fontFamily: "Barlow Regular, sans-serif",
        fontSize: "17px",
        "::placeholder": {
            color: "#757575"
        }
    },
    invalid: {
        color: "#f00"
    }
};

const Button = styled(BrandButton)`
    font-family: Barlow;
    font-size: 21px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    height: 56px;
    border-radius: 100px;
    width: 100%;
    max-width: 284px;
    margin: 24px auto;
`;
