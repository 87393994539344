import * as React from "react";
import classnames from "classnames";
import { Link } from "@fitplan/lib/components/Link";

import Spinner from "../subscriptionNewVersion/spinner";

import "./button.scss";

interface Props {
    className?: string;
    loading?: boolean;
    disabled?: boolean;
    text?: string;
    to: string;
    cancel?: boolean;
}

export const Button: React.FunctionComponent<Props> = ({
    className,
    disabled,
    loading,
    text,
    cancel,
    children,
    to,
    ...props
}) => {
    const classname = classnames(
        "button",
        {
            "button--disabled": disabled,
            "button--text-only": text,
            "button--cancel": cancel,
            "button--loading": loading,
        },
        className
    );

    const content = loading ? <Spinner /> : children;

    return to ? (
        <Link to={to} className={classname} {...props}>
            {content}
        </Link>
    ) : (
        <button className={classname} {...props}>
            {content}
        </button>
    );
};
