import * as React from "react";
import { IOneTimeProduct } from "../../types";
import { useAuthContext } from "@fitplan/context/lib-es/auth";

import { Section, Container, Header } from "../NewSubscriptionModal/elements";
import { SignUpForm, SignUpFooter } from "../UserAuth/SignUpForm";
import { LoginForm, LoginFooter } from "../UserAuth/LoginForm";
import { UserView } from "../NewSubscriptionModal/UserView";
import { PlanView } from "../NewSubscriptionModal/PlanView";
import { PaymentForm } from "./PaymentForm";
import { PlanSelect } from "../NewSubscriptionModal/PlanSelect";
import axios from "axios";
import { useConfig } from "@fitplan/context/lib-es/config";
import { sentryReportException } from "../../utils/sentry";

export interface ProductModalProps {
    product: IOneTimeProduct;
    language: "en" | "es";
}

export const ProductModal: React.FunctionComponent<ProductModalProps> = ({
    product,
    language,
    children,
    ...props
}) => {
    const { user, access_token } = useAuthContext();
    const { apiBaseUrl } = useConfig();
    const [complete, setComplete] = React.useState(false);
    const [showLogin, setShowLogin] = React.useState(false);
    const [clientSecret, setClientSecret] = React.useState("");
    React.useEffect(() => {
        if (user && access_token && product?.code) {
            if (user?.oneTimeProducts?.filter?.(prod => prod?.code === product.code)?.length > 0) {
                setComplete(true);
            } else {
                axios({
                    method: "POST",
                    url: `${apiBaseUrl}/v1/one_time_product/${product.code}`,
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                }).then((result) => {
                    if (result?.data?.result) {
                        setClientSecret(result.data.result);
                    }
                }).catch((err) => {
                    sentryReportException(err);
                });
            }
        }
    }, [user, access_token, product]);
    return complete ? (
        <Section active standalone>
            {children}
        </Section>
    ) : (
        <Container>
            <Section active={!user}>
                {user && (
                    <UserView
                        name={`${user.firstName} ${user.lastName}`}
                        email={user.username}
                        avatarUrl={user.profileImage}
                    />
                )}
                {!user &&
                    (showLogin ? (
                        <>
                            <Header>1. Login</Header>
                            <LoginForm onComplete={() => {}} />
                            <LoginFooter onClick={() => setShowLogin(false)} />
                        </>
                    ) : (
                        <>
                            <Header>1. Create Account</Header>
                            <SignUpForm onComplete={() => {}} />
                            <SignUpFooter onClick={() => setShowLogin(true)} />
                        </>
                    ))}
            </Section>
            <Section>
                <PlanSelect showButton={false} buttonText="" onClick={() => {}}>
                    <PlanView
                        title={product.name}
                        subtitle={`$${product.amount / 100} due today`}
                    />
                </PlanSelect>
            </Section>
            <Section active={!!(user && clientSecret)}>
                <PaymentForm
                    product={product}
                    clientSecret={clientSecret}
                    disabled={!(user && clientSecret)}
                    onComplete={() => setComplete(true)}
                    language={language}
                    key={clientSecret}
                />
            </Section>
        </Container>
    );
};
