import React from "react";
import styled from "@emotion/styled";

const svgStyle = {
    fill: "#000",
    width: "100px",
};

const IconMealLogo: React.FunctionComponent<{}> = () => (
    <PathStyle
        xmlns="http://www.w3.org/2000/svg"
        style={svgStyle}
        viewBox="0 0 288 200"
    >
        <g>
            <path
                d="M188,169.18c-0.1-0.4-0.24-0.75-0.28-1.12c-0.05-0.41-0.01-0.84-0.01-1.26c0-9.21,0-18.42-0.01-27.63c0-0.21,0-0.42,0-0.63
                c-0.03-1-0.24-1.23-1.26-1.24c-1.29-0.02-2.58,0.01-3.75,0.02c-0.57,0.63-0.46,1.25-0.46,1.83c-0.01,7.35,0,14.71,0,22.06
                c0,2.21,0,4.41,0,6.62c0,0.38-0.03,0.76-0.05,1.09c-0.8,0.39-4.81,0.5-6.4,0.2c-0.4-0.39-0.29-0.9-0.3-1.38
                c-0.01-4.17,0-8.33,0-12.5c0-5.46,0-10.93,0-16.39c0-0.51,0.1-1.07-0.48-1.54c-1.57,0.03-3.24-0.24-5.02,0.18c0,0.51,0,0.96,0,1.4
                c0,9.59,0,19.19,0,28.78c0,0.45,0,0.89,0,1.28c-0.87,0.33-5.18,0.4-6.61,0.14c-0.04-0.29-0.12-0.62-0.12-0.95
                c-0.02-1.09-0.01-2.17-0.01-3.26c0-12.08,0-24.16,0-36.24c0-0.51,0.04-1.03,0.06-1.6c0.39-0.03,0.7-0.07,1.01-0.07
                c8.59,0,17.17,0.02,25.76-0.02c1.27,0,2.31,0.39,2.96,1.42c0.51,0.81,0.86,1.76,1.11,2.69c0.41,1.53,0.42,3.11,0.42,4.69
                c-0.02,10.47-0.01,20.94-0.01,31.41c0,0.25,0,0.49,0,0.74c-0.01,1.18-0.17,1.34-1.33,1.35c-1.37,0.01-2.73,0.01-4.1,0
                C188.77,169.26,188.43,169.21,188,169.18z"
            />
            <path
                d="M50.25,148.46c0.11-0.36,0.1-0.64-0.24-0.92c-0.48-0.4-0.8-0.93-0.98-1.56c-0.16-0.56-0.23-1.08,0.04-1.63
                c0.29-0.58,0.25-1.24,0.67-1.83c0.71-1,1.57-1.74,2.6-2.35c0.23-0.13,0.41-0.35,0.7-0.61c-0.22-0.29-0.38-0.55-0.58-0.76
                c-1.84-1.8-3.57-3.69-4.99-5.85c-0.6-0.92-1.32-1.81-1.69-2.82c-0.36-0.99-0.75-2.04-0.6-3.15c0.15-1.1,0.58-2.09,1.4-2.83
                c0.71-0.64,1.31-1.43,2.29-1.74c0.16-0.05,0.28-0.2,0.38-0.28c0.08-0.93-0.4-1.57-0.78-2.2c-0.82-1.35-1.66-2.71-2.65-3.94
                c-0.71-0.88-1.67-1.57-2.56-2.28c-0.64-0.52-1.34-0.98-2.06-1.4c-1.18-0.7-2.11-1.68-2.96-2.72c-0.55-0.68-0.96-1.49-1.42-2.24
                c-0.37-0.59-0.82-1-1.6-0.96c-0.84,0.05-1.68-0.04-2.52,0.01c-1.35,0.08-2.5,0.62-3.24,1.8c-0.54,0.86-0.61,1.85-0.54,2.83
                c0.13,1.86,0.66,3.63,1.36,5.34c0.84,2.08,1.95,4.01,3.27,5.83c0.74,1.02,1.42,2.07,2.45,2.82c0.89,0.65,1.76,1.33,2.71,1.87
                c1.32,0.75,2.7,1.38,4.07,2.03c0.31,0.15,0.67,0.18,1.06,0.27c0.09,0.25,0.17,0.51,0.27,0.78c-0.14,0.04-0.24,0.08-0.34,0.1
                c-0.1,0.01-0.21,0.02-0.31,0c-1.48-0.36-3.01-0.58-4.43-1.12c-2.27-0.85-4.39-2.03-6.22-3.65c-1.78-1.57-3.31-3.37-4.61-5.35
                c-0.66-1.01-1.37-2-1.9-3.08c-0.51-1.05-0.86-2.18-1.22-3.3c-0.31-0.95-0.6-1.92-0.76-2.91c-0.31-1.86-0.51-3.74,0.15-5.59
                c0.23-0.65,0.46-1.3,0.61-1.97c0.35-1.54,1.39-2.52,2.69-3.22c1.07-0.57,2.25-0.88,3.49-0.86c0.62,0.01,1.24,0,1.99,0
                c-0.03-0.42,0-0.73-0.07-1.01c-0.55-2.15-0.49-4.28,0.17-6.4c0.08-0.27,0.34-0.54,0.59-0.68c0.64-0.35,1.3-0.66,1.98-0.9
                c0.71-0.26,1.47-0.4,2.19-0.66c0.64-0.23,1.25-0.56,1.88-0.83c0.28-0.12,0.59-0.18,0.89-0.27c0.47-0.13,0.94-0.25,1.41-0.38
                c0.13-0.04,0.26-0.11,0.4-0.13c1.38-0.18,2.77-0.37,4.15-0.54c1.39-0.17,2.78-0.39,4.17-0.44c2.38-0.09,4.77-0.18,7.14-0.05
                c1.87,0.1,3.73,0.52,5.59,0.83c1.27,0.21,2.57,0.37,3.78,0.77c0.84,0.27,1.72,0.37,2.55,0.83c0.92,0.51,2.04,0.65,3.17,0.98
                c0.11,0.14,0.27,0.35,0.48,0.63c-0.93-0.08-1.8-0.12-2.66-0.25c-5.29-0.79-10.61-0.54-15.93-0.49c-1.28,0.01-2.56,0.35-3.85,0.45
                c-1.82,0.13-3.61,0.48-5.32,1.05c-1.42,0.47-2.84,1.08-4,2.11c-0.36,0.32-0.86,1.34-0.87,2.06c-0.02,2.17-0.15,4.36,0.06,6.51
                c0.18,1.88,0.69,3.75,1.93,5.3c0.11,0.13,0.23,0.25,0.35,0.38c1.59,1.72,3.17,3.44,4.46,5.4c0.74,1.12,1.22,2.31,1.39,3.65
                c0.13,1.03,0.37,2.05,0.52,3.09c0.08,0.58,0.07,1.17,0.11,1.87c3.62-0.46,7.14-0.72,10.83,0.33c-0.28,0.18-0.38,0.28-0.48,0.3
                c-0.82,0.15-1.61,0.28-2.38,0.69c-0.75,0.4-1.66,0.48-2.5,0.69c-1.16,0.29-2.27,0.7-3.12,1.58c-0.79,0.81-1.39,1.77-1.49,2.9
                c-0.11,1.18-0.11,2.39,0.02,3.56c0.09,0.8,0.44,1.59,0.77,2.35c0.4,0.94,0.84,1.88,1.36,2.76c0.45,0.76,1.21,1.22,1.96,1.66
                c0.68,0.4,1.41,0.74,2.1,1.14c0.25,0.14,0.45,0.38,0.73,0.63c-0.77,0.52-1.46,0.92-2.07,1.42c-0.44,0.36-0.9,0.81-1.1,1.32
                c-0.51,1.28-0.1,2.43,1.11,3.26c0.6,0.41,1.28,0.71,1.94,1.01c0.61,0.28,1.25,0.5,2.02,0.8c-1.17,0.82-2.34,0.97-3.52,0.98
                c-1.19,0.01-2.22,0.28-3.16,1.06c-1.06,0.88-2.29,1.51-3.26,2.55c-1.31,1.41-1.59,3-1.38,4.93c1.39,0.36,2.79,0.65,4.13,1.09
                c2.02,0.66,4.08,0.42,6.13,0.47c2.15,0.05,4.22-0.44,6.29-0.95c0.91-0.22,1.82-0.42,2.71-0.62c0.33-0.54,0.27-1.09,0.16-1.58
                c-0.18-0.85-0.41-1.7-0.71-2.51c-0.33-0.9-0.79-1.76-1.14-2.66c-0.11-0.28-0.05-0.63-0.07-1.08c2.09,1.75,3.72,3.66,5.3,5.62
                c0.6,0.74,1.03,1.62,1.55,2.42c0.2,0.31,0.47,0.59,0.67,0.9c0.25,0.39,0.44,0.81,0.04,1.28c-1.86,0.77-3.85,1.23-5.82,1.79
                c-1.01,0.28-2.02,0.45-3.04,0.67c-3.2,0.68-6.43,1.01-9.7,0.8c-2.16-0.14-4.34-0.24-6.47-0.61c-1.17-0.2-2.4-0.24-3.54-0.82
                c-1.01-0.51-2.18-0.67-3.28-1.01c-0.66-0.21-1.3-0.48-1.93-0.72c-0.33-0.33-0.37-0.65-0.16-1.05c1.13-2.1,2.21-4.22,3.95-5.93
                c1.04-1.03,2.17-1.92,3.38-2.72c0.83-0.55,1.62-1.17,2.53-1.6C49.57,148.85,49.86,148.68,50.25,148.46z"
            />
            <path
                d="M110.49,148.01c0-4.41,0-8.82,0-13.24c0-1.87,0.37-3.68,0.99-5.44c0.34-0.97,1.02-1.67,1.9-2.14
                c0.34-0.18,0.81-0.19,1.22-0.19c2.52-0.02,5.05-0.01,7.57-0.02c1.33,0,2.66-0.01,4,0.01c1.38,0.02,2.76,1.02,3.17,2.32
                c0.47,1.5,0.87,3.01,0.98,4.59c0.02,0.35,0.04,0.7,0.04,1.05c0,8.89,0,17.79,0,26.68c0,1.84-0.34,3.63-1.04,5.32
                c-0.47,1.13-1.78,2.35-3.43,2.32c-3.61-0.07-7.22-0.06-10.83,0c-1.71,0.03-2.93-1.01-3.58-2.4c-0.77-1.66-0.96-3.44-0.98-5.21
                C110.44,157.12,110.48,152.57,110.49,148.01C110.48,148.01,110.48,148.01,110.49,148.01z M123.21,137.33
                c-1.71,0-3.38-0.02-5.05,0.01c-0.49,0.01-0.75,0.34-0.78,0.83c-0.01,0.21-0.02,0.42-0.02,0.63c0,6.3,0,12.6,0,18.89
                c0,0.34,0.05,0.68,0.07,0.98c0.81,0.34,4.66,0.41,5.91,0.12c0.09-0.36,0.27-0.79,0.28-1.21c0.02-0.84-0.09-1.67-0.09-2.51
                c-0.01-4.27-0.01-8.54,0-12.81c0-0.49,0.06-0.98,0.07-1.46C123.64,139.68,123.79,138.55,123.21,137.33z"
            />
            <path
                d="M146.6,169.26c-0.57-0.5-0.56-1.03-0.56-1.56c0-2.84,0-5.67,0-8.51c0-6.72,0-13.45,0-20.17c0-1.63-0.11-1.73-1.72-1.73
                c-0.98,0-1.96-0.02-2.94,0c-0.37,0.01-0.75,0.08-1.22,0.13c-0.03,0.58-0.08,1.09-0.08,1.61c0,9.49-0.01,18.98,0.01,28.47
                c0,1.63-0.1,1.8-1.76,1.77c-1.3-0.03-2.59,0-3.89-0.01c-0.99-0.01-1.24-0.25-1.25-1.25c-0.01-2.45,0-4.9,0-7.35
                c0-10.65-0.01-21.29-0.01-31.94c0-0.52,0.04-1.03,0.07-1.63c0.42-0.04,0.76-0.09,1.1-0.09c4.77,0,9.53,0.03,14.3-0.02
                c1.45-0.01,2.35,0.67,3.02,1.84c0.9,1.59,1.2,3.34,1.27,5.13c0.07,1.68,0.02,3.36,0.02,5.04c0,9.53,0,19.05,0,28.58
                c0,1.63-0.07,1.69-1.74,1.69c-1.23,0-2.45,0-3.68,0C147.17,169.26,146.82,169.26,146.6,169.26z"
            />
            <path
                d="M108.38,127.11c-0.19,1.7-0.55,3.3-0.94,4.92c-0.39,1.61-0.56,3.28-0.79,4.74c-0.5,0.61-1.01,0.53-1.5,0.53
                c-1.93,0-3.86-0.01-5.78,0.01c-1.09,0.01-1.29,0.21-1.29,1.31c0,6.48,0,12.96,0.01,19.43c0,0.17,0.02,0.34,0.03,0.51
                c0.48,0.42,1.05,0.4,1.6,0.4c2.38,0,4.77,0,7.15,0c0.41,0,0.82,0,1.17,0c0.44,0.65,0.28,1.2,0.18,1.75
                c-0.18,0.92-0.37,1.85-0.54,2.77c-0.31,1.6-0.6,3.2-0.93,4.8c-0.15,0.75-0.44,0.98-1.25,0.98c-3.36,0.01-6.73,0-10.09,0.02
                c-0.72,0-1.33-0.29-1.81-0.72c-0.84-0.75-1.36-1.71-1.69-2.82c-0.55-1.89-0.54-3.8-0.54-5.72c0-8.4,0.03-16.81-0.02-25.21
                c-0.01-1.79,0.41-3.46,0.86-5.14c0.23-0.85,0.78-1.48,1.4-2.04c0.38-0.34,0.82-0.61,1.41-0.6c4.28,0.02,8.55,0.01,12.83,0.01
                C107.96,127.01,108.06,127.05,108.38,127.11z"
            />
            <path
                d="M214.83,146.63c-0.02-0.36-0.08-0.57-0.04-0.77c0.53-2.49,1.06-4.97,1.62-7.46c0.05-0.22,0.24-0.41,0.4-0.66
                c0.35-0.02,0.69-0.05,1.04-0.05c2.56,0,5.12,0.07,7.67-0.01c1.85-0.06,2.85,0.99,3.55,2.42c0.79,1.62,1.08,3.36,1.07,5.17
                c-0.03,7.53-0.02,15.05-0.02,22.58c0,0.38,0,0.76,0,1.24c-0.42,0.06-0.75,0.16-1.08,0.16c-3.4,0.01-6.8,0-10.2,0
                c-0.93,0-1.66-0.39-2.28-1.08c-1.12-1.25-1.75-2.78-1.83-4.36c-0.15-2.75-0.07-5.52,0.06-8.28c0.06-1.25,0.64-2.4,1.34-3.47
                c0.71-1.09,1.67-1.6,2.97-1.59c1.29,0.01,2.59-0.04,3.88-0.08c0.24-0.01,0.47-0.08,0.72-0.13c0.45-1.12,0.35-2.21,0.08-3.43
                c-0.41-0.04-0.81-0.11-1.22-0.11c-2.14-0.01-4.27,0-6.41-0.01C215.75,146.72,215.34,146.66,214.83,146.63z M223.62,160.85
                c0.49-1.1,0.5-3.03,0.04-4.15c-0.99-0.08-2.01-0.19-2.99,0.07c-0.36,1.21-0.32,3.16,0.07,3.97c0.18,0.03,0.38,0.1,0.58,0.11
                C222.08,160.86,222.84,160.85,223.62,160.85z"
            />
            <path
                d="M203.22,156.55c0,1.12-0.02,2.09,0.01,3.06c0.01,0.4,0.32,0.61,0.72,0.62c0.28,0.01,0.56,0.01,0.84,0.01
                c2.21,0,4.41-0.01,6.62,0c0.37,0,0.75,0.06,1.26,0.1c0,0.38,0.06,0.69-0.01,0.98c-0.59,2.6-1.02,5.23-1.79,7.83
                c-0.43,0.04-0.77,0.1-1.11,0.1c-2.77,0.01-5.54,0-8.3,0.01c-1.09,0-1.91-0.48-2.59-1.32c-1.31-1.6-1.69-3.5-1.7-5.47
                c-0.04-5.92-0.04-11.83-0.01-17.75c0.01-1.36,0.09-2.75,0.65-4.01c0.48-1.07,1.02-2.17,2.17-2.69c0.46-0.21,1-0.31,1.51-0.32
                c2.17-0.03,4.35,0.06,6.52-0.02c1.83-0.07,2.85,0.93,3.57,2.4c0.83,1.69,1.13,3.5,1.08,5.38c-0.03,1.15-0.03,2.31,0,3.47
                c0.04,1.24-0.11,2.46-0.49,3.62c-0.37,1.16-0.87,2.27-1.84,3.09c-0.62,0.52-1.26,0.82-2.09,0.79c-1.26-0.04-2.52-0.01-3.78,0
                C204.11,156.45,203.77,156.5,203.22,156.55z M203.76,150.28c0.89,0,1.65,0,2.39,0c0.76-0.97,0.9-2.87,0.31-4.09
                c-1.01,0-2.03,0-3.17,0C203.25,147.62,202.79,148.99,203.76,150.28z"
            />
            <path
                d="M257.98,137.81c-0.07,0.96-0.14,1.8-0.42,2.66c-0.35,1.09-0.51,2.24-0.69,3.38c-0.12,0.77-0.26,1.52-0.79,2.28
                c-2.26,0-4.6,0-6.96,0c-0.39,1.08-0.38,2.05-0.02,3.14c0.93,0.01,1.83,0.01,2.73,0.04c0.73,0.03,1.47,0.06,2.19,0.17
                c0.63,0.09,1.08,0.46,1.53,0.96c1.06,1.17,1.52,2.53,1.81,4.04c0.49,2.57,0.35,5.17,0.28,7.74c-0.03,1.31-0.46,2.6-0.76,3.9
                c-0.24,1.07-0.96,1.85-1.74,2.54c-0.44,0.39-1,0.62-1.64,0.61c-3.33-0.01-6.66,0-9.99,0c-0.27,0-0.54-0.07-0.94-0.13
                c0.12-1.4,0.4-2.7,0.74-4.01c0.33-1.28,0.53-2.59,0.77-3.85c0.32-0.42,0.75-0.4,1.17-0.41c1.51,0,3.01-0.01,4.52-0.01
                c0.38,0,0.77,0,1.28,0c0.16-0.35,0.45-0.7,0.46-1.06c0.02-0.81,0.3-1.7-0.66-2.45c-1.52,0-3.19,0-4.71,0
                c-1.18-0.79-2.02-1.67-2.53-2.79c-0.53-1.18-0.85-2.45-0.85-3.75c0-2.66-0.01-5.32,0.15-7.97c0.1-1.61,0.78-3.08,1.93-4.28
                c0.51-0.53,1.11-0.83,1.85-0.83c3.54-0.01,7.08-0.01,10.62-0.02C257.53,137.7,257.7,137.76,257.98,137.81z"
            />
            <path
                d="M65.26,136.49c0.01-0.41-0.08-0.86,0.05-1.24c0.57-1.73,0.45-3.51,0.46-5.28c0.01-1.19-0.2-2.36-0.59-3.48
                c-0.33-0.94-0.75-1.86-1.22-2.74c-0.43-0.81-0.7-1.64-0.81-2.53c-0.06-0.48-0.05-0.98-0.07-1.47c-0.11-2.61,0.99-4.69,2.88-6.39
                c1.31-1.18,2.7-2.26,3.88-3.58c0.82-0.92,1.73-1.76,2.47-2.73c0.99-1.28,1.74-2.72,2.17-4.3c0.38-1.37,0.72-2.75,1.1-4.12
                c0.1-0.35,0.31-0.66,0.57-1.19c0.21,0.58,0.33,0.92,0.46,1.28c0.4,0.4,0.89,0.28,1.38,0.23c1.78-0.2,3.58-0.34,5.33,0.06
                c1.85,0.42,3.56,1.14,4.66,2.85c0.59,0.92,0.95,1.93,1.23,2.96c0.72,2.6,0.42,5.19-0.25,7.76c-0.4,1.52-1.03,2.93-1.64,4.35
                c-0.43,1-1.03,1.94-1.65,2.84c-0.68,0.98-1.53,1.86-2.2,2.84c-1.17,1.71-2.77,2.96-4.44,4.12c-1.07,0.75-2.14,1.51-3.46,1.84
                c-0.55,0.14-1.01,0.57-1.63,0.63c-0.65,0.07-1.29,0.25-1.93,0.38c-0.14,0.03-0.29,0-0.42,0.04c-0.71,0.24-1.42,0.49-2.35,0.81
                c-0.03,0.05-0.26,0.29-0.36,0.57c-0.44,1.28-1.09,2.44-1.95,3.49c-0.5,0.61-0.93,1.27-1.4,1.9c-0.05,0.06-0.2,0.04-0.3,0.06
                L65.26,136.49z M69.41,129.27c0.58-0.1,1.18-0.07,1.65-0.3c1.23-0.59,2.42-1.28,3.61-1.95c1.39-0.77,2.73-1.61,3.87-2.74
                c0.91-0.9,1.54-2.02,2.32-3.01c0.77-0.99,1.3-2.11,1.84-3.22c0.43-0.9,0.73-1.86,1.11-2.79c0.69-1.63,0.95-3.32,0.97-5.1
                c0.02-1.97-1.54-3.52-3.51-3.71c-1.14-0.11-2.31-0.04-3.46,0.02c-0.67,0.04-1.31,0.27-1.68,0.9c-0.34,0.56-0.73,1.11-0.95,1.72
                c-0.35,1-1.11,1.68-1.82,2.34c-1.73,1.63-3.55,3.15-4.95,5.12c-0.96,1.35-1.4,2.84-1.64,4.43c-0.04,0.24-0.06,0.53,0.04,0.73
                c0.47,0.92,0.57,1.98,1.18,2.87c0.81,1.21,1.36,2.56,1.28,4.08C69.27,128.83,69.34,129,69.41,129.27z"
            />
            <path
                d="M33.14,169.17c0.84-2.08,1.62-4.04,2.42-6.04c0.75-0.22,2.03,0.19,2.79,0.9c1.08,0.31,2.06,0.55,3.02,0.86
                c2.23,0.72,4.53,1.09,6.84,1.46c1.7,0.28,3.39,0.46,5.1,0.5c2.1,0.05,4.2,0.03,6.3,0.02c0.38,0,0.77-0.07,1.15-0.08
                c2.07-0.04,4.09-0.44,6.11-0.82c1.23-0.24,2.46-0.46,3.68-0.75c0.94-0.23,1.86-0.56,2.8-0.83c0.4-0.12,0.81-0.2,1.25-0.3
                c0.72-0.69,1.64-1,2.74-1.15c0.8,2.08,1.67,4.09,2.33,6.13c-0.75,0.85-1.75,0.8-2.54,1.2c-0.15,0.07-0.4,0.01-0.5,0.12
                c-1,0.98-2.34,0.95-3.56,1.27c-0.94,0.24-1.84,0.63-2.78,0.84c-1.05,0.24-2.13,0.4-3.2,0.54c-2.04,0.27-4.08,0.55-6.12,0.77
                c-2.28,0.25-4.54,0.24-6.81,0.21c-1.81-0.02-3.63-0.21-5.43-0.45c-1.94-0.25-3.86-0.61-5.77-1c-0.95-0.19-1.86-0.55-2.8-0.8
                c-0.84-0.23-1.68-0.41-2.52-0.63c-0.23-0.06-0.53-0.07-0.68-0.22c-0.68-0.67-1.57-0.82-2.42-1.08
                C34.08,169.71,33.7,169.45,33.14,169.17z"
            />
            <path
                d="M81.86,127.04c2.29,0,4.38,0,6.58,0c0.09,0.33,0.26,0.65,0.26,0.97c-0.01,3.04-0.07,6.09-0.08,9.13
                c-0.01,6.97-0.01,13.94,0,20.9c0.01,3.32,0.07,6.65,0.08,9.97c0,0.38-0.21,0.77-0.33,1.14c-0.36,0.04-0.6,0.09-0.84,0.09
                c-1.58,0.01-3.15,0.02-4.73,0c-0.82-0.01-1.05-0.25-1.08-1.06c-0.01-0.24,0-0.49,0-0.74c0-12.82,0-25.63,0.01-38.45
                C81.73,128.39,81.81,127.78,81.86,127.04z"
            />
            <path
                d="M238.94,159.72c0.85,0.9,1.94,0.13,2.73,0.97c-0.6,2.75-1.22,5.57-1.85,8.5c-1.24,0.1-2.43,0.23-3.58-0.06
                c-1.25-0.31-1.91-1.39-2.43-2.43c-0.69-1.39-0.92-2.94-0.93-4.48c-0.02-11.73-0.02-23.47-0.02-35.2c0-1.02,0.21-1.23,1.24-1.24
                c1.23-0.01,2.45-0.01,3.68,0c0.98,0.01,1.13,0.17,1.16,1.18c0.01,0.32,0,0.63,0,0.95c0,10.23,0,20.45-0.01,30.68
                C238.94,159,238.94,159.41,238.94,159.72z"
            />
            <path
                d="M65.24,136.46c-0.04,0.19-0.07,0.39-0.12,0.58c-0.01,0.05-0.1,0.08-0.23,0.17c-0.08-0.2-0.16-0.38-0.27-0.64
                c0.27-0.03,0.46-0.05,0.64-0.07L65.24,136.46z"
            />
        </g>
    </PathStyle>
);

const PathStyle = styled.svg`
    .cls-1 {
        fill: red;
    }
    g:hover > path.cls-1 {
        fill: #00ffb3 !important;
    }
`;

export default IconMealLogo;
